<script>
import { defineComponent } from 'vue'

import KIcon from './KIcon.vue'
import KSpinner from './KSpinner.vue'
import { convertToClassString } from '@/utils/class'

export default defineComponent({
    name: 'KButton',
    components: {
        KIcon,
        KSpinner,
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop, vue/no-reserved-props
        class: [String, Array, Object],
        type: {
            type: String,
            default: 'button',
        },
        iconLeft: {
            type: String,
            default: undefined,
        },
        iconRight: {
            type: String,
            default: undefined,
        },
        icon: {
            type: String,
            default: undefined,
        },
        mode: {
            type: String,
            default: 'primary',
        },
        danger: Boolean,
        href: {
            type: String,
            default: undefined,
        },
        to: {
            type: [String, Object],
            default: undefined,
        },
        size: {
            type: String,
            default: 'XL',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        lowercase: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        class_list() {
            return {
                'transition-all border border-transparent relative inline-block': true,

                'text-black': this.mode === 'secondary' && !this.disabled,
                'text-link': this.mode === 'link',
                'text-white': this.mode === 'primary' && !this.disabled,
                'text-secondary--text': this.disabled,

                'bg-brand hover:bg-brand--hover': this.mode === 'primary' && !this.disabled,
                'border-brand hover:border-brand--hover': this.mode === 'primary' && !this.disabled,
                'bg-white hover:bg-primary--background':
                    this.mode === 'secondary' && !this.disabled,
                'bg-secondary--graphic':
                    (this.mode === 'secondary' || this.mode === 'primary') && this.disabled,

                'bg-red border-red hover:bg-red--hover hover:border-red--hover':
                    this.danger && this.mode !== 'text' && this.mode !== 'link',

                'border-primary--border': this.mode === 'secondary',
                'shadow-sm': this.mode === 'primary' || this.mode === 'secondary',
                'rounded-md':
                    this.mode === 'primary' || this.mode === 'secondary' || this.mode === 'icon',

                'px-6 py-3': this.size === 'XL' && this.mode !== 'icon' && this.mode !== 'link',
                'px-4 py-1.5 ': this.size === 'M' && this.mode !== 'icon' && this.mode !== 'link',

                'p-3': this.mode === 'icon',

                'hover:text-link--hover':
                    (this.mode === 'text' || this.mode === 'link' || this.mode === 'icon') &&
                    !this.disabled,
                'text-red hover:text-red--hover':
                    this.danger && (this.mode === 'text' || this.mode === 'link'),

                'inline-block': this.mode === 'text' || this.mode === 'link',
                'font-medium': this.mode !== 'text' && this.mode !== 'link',
                'cursor-pointer': !this.disabled,
            }
        },
        class_list_string() {
            return convertToClassString([this.class_list, this.class])
        },
    },
})
</script>

<template>
    <component
        :is="to ? 'router-link' : href ? 'a' : 'button'"
        :class="class_list_string"
        :disabled="disabled || loading"
        v-bind="to ? { to, activeClass: '!text-link' } : href ? { href } : { type }"
    >
        <div
            v-if="loading"
            class="absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center transition"
        >
            <KSpinner :color="mode === 'primary' ? '#fff' : 'var(--color-brand)'" class="-my-2" />
        </div>
        <div
            :class="{
                'opacity-0': loading,
                'justify-center': mode !== 'text' && mode !== 'link',
            }"
            class="flex items-center"
        >
            <KIcon v-if="iconLeft" :icon="iconLeft" class="mr-3" />
            <KIcon v-if="icon" :icon="icon" />
            <div :class="{ 'first-letter:capitalize': !lowercase }"><slot /></div>
            <KIcon v-if="iconRight" :icon="iconRight" class="ml-auto" />
        </div>
    </component>
</template>

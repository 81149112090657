<script>
import { mapGetters } from 'vuex'

export default {
    name: 'StaticPagesLinks',
    computed: {
        ...mapGetters({
            slug: 'project/slug',
            to_terms_props: 'project/to_terms_props',
            to_privacy_props: 'project/to_privacy_props',
        }),
    },
}
</script>

<template>
    <div v-if="to_terms_props || to_privacy_props">
        <KButton
            v-if="to_privacy_props"
            v-bind="to_privacy_props"
            mode="text"
            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
            size="M"
            target="_blank"
        >
            {{ $t('privacy policy') }}
        </KButton>
        <KButton
            v-if="to_terms_props"
            v-bind="to_terms_props"
            mode="text"
            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
            size="M"
            target="_blank"
        >
            {{ $t('terms and conditions') }}
        </KButton>
    </div>
</template>

import { isEmpty, orderBy } from 'lodash'
import { isPast } from 'date-fns'

import api from '@/api'

export default {
    namespaced: true,
    state: {},
    getters: {
        project(state, getters, { store }) {
            return store?.project ? { ...store?.project, ...store?.project?.meta } : {}
        },
        have_project(state, getters, { store }) {
            return !!store?.project?.id
        },
        slug(state, { project }) {
            return project?.slug
        },

        plans(state, getters, rootState, rootGetters) {
            return orderBy(
                (getters.project.plans || []).map(plan => {
                    const addToPlan = {
                        available_quantity: plan.quantity
                            ? plan.quantity - plan.plan_passes_count || 0
                            : 999,
                    }

                    if (rootGetters['cart/dynamic_pricing'][plan.id]) {
                        addToPlan.old_price = plan.amount
                        let amount = Number(rootGetters['cart/dynamic_pricing'][plan.id] || 0)
                        addToPlan.amount = amount
                        addToPlan.tax_amount =
                            amount -
                            Math.round(
                                (amount * rootGetters.tax_percent) / (1 + rootGetters.tax_percent)
                            )
                        addToPlan.price_without_tax = amount - addToPlan.tax_amount
                    }

                    return { ...plan, ...addToPlan }
                }),
                ['sales_start', 'available_quantity', 'name'],
                ['asc', 'desc', 'asc']
            )
        },
        plans_by_id(state, { plans }) {
            return plans.reduce((sum, plan) => {
                return { ...sum, [plan.id]: plan }
            }, {})
        },
        has_registration(state, { project }) {
            return project?.has_registration
        },
        has_data_collection(state, getters) {
            if (!getters.project) return false
            if (getters.is_product) return !isEmpty(getters.form_fields)

            return getters.project.has_data_collection
        },
        form_fields(state, getters) {
            return getters.project?.form_fields
        },

        project_type(state, getters) {
            return getters.project?.type
        },
        project_subtype(state, getters) {
            return getters.project?.subtype
        },
        is_published(state, getters) {
            return getters.project?.is_published
        },
        is_product(state, getters) {
            return getters.project?.is_product
        },
        is_stampcard(state, getters) {
            return getters.project_type === 'loyalty' && getters.project_subtype === 'stamp'
        },
        is_coupon(state, getters) {
            return getters.project_type === 'coupon'
        },
        is_voucher_coupon(state, getters) {
            return getters.is_coupon && getters.project_subtype == 'voucher'
        },
        is_membership(state, getters) {
            return getters.project_type === 'loyalty' && getters.project_subtype === 'member'
        },
        is_voucher(state, getters) {
            return getters.project_type === 'voucher'
        },
        is_event(state, getters) {
            return getters.project_type === 'ticket' && getters.project_subtype === 'event'
        },
        is_admission(state, getters) {
            return getters.project_type === 'ticket' && getters.project_subtype === 'admission'
        },
        is_monetary(state, getters) {
            return getters.project_subtype === 'monetary'
        },
        is_kaisuken(state, getters) {
            return getters.project_subtype === 'kaisuken'
        },
        is_transport(state, getters) {
            return getters.project_type === 'transport'
        },
        is_lift(state, getters) {
            return getters.is_transport && getters.project_subtype === 'lift'
        },
        is_transport_bus(state, getters) {
            return getters.project?.transport_type === 'PKTransitTypeBus'
        },
        is_bundle(state, getters) {
            return getters.project_subtype === 'bundle'
        },
        bundle_venues(state, getters) {
            return (getters.is_bundle && getters.project?.bundle_venues) || []
        },
        bundle_venues_with_reservation(state, getters) {
            return getters.bundle_venues.filter(v => v.reservation)
        },
        bundle_units_with_reservation(state, getters) {
            return (getters.project?.booking_units || []).filter(u =>
                getters.bundle_venues_with_reservation.find(v => v.id === u.venue_id)
            )
        },
        is_whole_line(state, getters) {
            return getters.is_lift || getters.project?.pass_layout === 'whole_line'
        },
        is_booking(state, getters) {
            return getters.project_type === 'booking' || getters.with_reservation
        },
        is_multiple_booking(state, getters) {
            return getters.project_type === 'booking' && getters.with_multiple_reservations
        },
        is_subscription(state, getters) {
            return getters.project_type === 'service'
        },
        is_meeting_pass(state, getters) {
            return getters.project_subtype === 'meeting_pass'
        },
        is_commuter(state, getters) {
            return getters.project_subtype === 'commuter'
        },
        is_all_day_booking(state, getters) {
            return !getters.project?.booking?.with_slots
        },
        with_reservation(state, getters) {
            return getters.project?.with_reservation
        },
        // special case for bundle tickets when second reservation depends on first
        with_linked_bundle(state, getters) {
            return getters.project?.with_linked_bundle
        },
        // for the future, use this flag to allow multiple reservations on one pass
        with_multiple_reservations(state, getters) {
            return getters.project?.with_multiple_reservations
        },
        with_seatmap(state, getters) {
            return getters.project?.with_seatmap
        },
        allow_reservation_changes(state, getters) {
            return getters.project?.allow_reservation_changes
        },
        with_authcode(state, getters) {
            return getters.project?.with_authcode
        },
        with_kyc(state, getters) {
            return getters.project?.with_kyc
        },
        with_lottery(state, getters) {
            return getters.project?.with_lottery && !getters.lottery_sales_ended
        },
        lottery_sales_ended(state, getters) {
            return getters.project?.with_lottery && isPast(getters.project.lottery.end)
        },
        max_reservations(state, getters) {
            return (
                (getters.with_linked_bundle
                    ? getters.bundle_units_with_reservation.length
                    : getters.project?.booking?.max_reservations) || 1
            )
        },
        redemption_price(state, { project }) {
            return project?.redemption_price
        },
        strip_src(state, { project }) {
            return project?.strip_src
        },
        wallpaper_src(state, getters) {
            return getters.project?.wallpaper_src
        },
        project_brand_name(state, { project }) {
            return project?.brand_name
        },
        with_individual_dc(state, { project }) {
            return project?.with_individual_dc
        },
        venues(state, { project }) {
            return project?.venues
        },
        fares(state, { project }) {
            return project?.fares
        },
        stations(state, { project }) {
            return project?.stations || []
        },
        booking_units(state, { project }) {
            return project?.booking_units
        },
        booking_need_confirmation(state, { project }) {
            return project?.booking?.need_confirmation
        },
        is_out_of_stock(state, { project }) {
            if (project?.plans?.length) {
                return project.plans.every(
                    plan => plan.quantity && plan.quantity <= plan.plan_passes_count
                )
            }

            if (project?.quantity === undefined || project?.quantity === null) {
                return false
            }

            if (project?.quantity === 0) return true

            const issued = project?.wallet_passes_count || 0

            return !(project?.quantity - issued)
        },
        order_limit(state, getters) {
            return getters.project?.order_limit
                ? Number(getters.project.order_limit)
                : getters.is_transport
                ? 1
                : 10
        },
        single_pass_project(state, getters) {
            return ['member'].includes(getters.project_type) || getters['with_single_purchase']
        },
        is_on_sale(state, { project }) {
            return project?.is_on_sale !== undefined ? project.is_on_sale : true
        },
        sales_not_started(state, getters) {
            if (!getters.is_on_sale) return true
            if (!getters.project?.has_sales_limits) return false

            return !getters.project?.allowed_sale_date
        },
        sales_start(state, getters) {
            if (!getters.project?.sales_start || isPast(getters.project?.sales_start)) return

            return getters.project?.sales_start
        },
        can_proceed(state, getters, rootState, rootGetters) {
            return (
                getters.is_published &&
                !rootGetters['is_shorturl_disabled'] &&
                !rootGetters['is_shorturl_expired'] &&
                !getters.sales_not_started &&
                !getters.is_out_of_stock &&
                !getters.is_ended &&
                !(getters.single_pass_project && rootGetters['account/active_passes']?.length)
            )
        },
        is_ended(state, getters) {
            if (getters.is_event) {
                return (
                    !!(getters.project?.start || getters.project?.end) &&
                    ((getters.project?.end && isPast(getters.project?.end)) ||
                        (getters.project?.start && isPast(getters.project?.start)))
                )
            }
            return (
                !!(getters.project?.start || getters.project?.end) &&
                getters.project?.end &&
                isPast(getters.project?.end)
            )
        },
        with_group_pass(state, { project }) {
            return project?.with_group_pass
        },
        with_force_group(state, { project }) {
            return project?.with_force_group
        },
        with_self_checkin(state, { project }) {
            return project?.with_self_checkin
        },
        with_konbini(state, { is_subscription }, rootState, { flags }) {
            return (flags.with_konbini && !is_subscription) || false
        },
        with_single_purchase(state, { project }) {
            return project?.with_single_purchase
        },
        need_quantity(state, { is_monetary, is_voucher }) {
            return is_monetary || is_voucher
        },
        self_checkin_timer(state, { project }) {
            return project?.self_checkin_timer || 3
        },
        total_stamps(state, { project }) {
            return project?.total_stamps
        },
        pass_use_limits(state, { project }) {
            return project?.limits
        },
        to_privacy_props(state, { slug }, rootState, { base_url, privacy_url }) {
            if (!privacy_url) return
            const page = 'privacy'

            return privacy_url.startsWith(base_url)
                ? { to: { name: 'projectStaticPage', params: { slug, page } } }
                : { href: privacy_url }
        },
        to_terms_props(state, { slug }, rootState, { base_url, terms_url }) {
            if (!terms_url) return
            const page = 'terms'

            return terms_url.startsWith(base_url)
                ? { to: { name: 'projectStaticPage', params: { slug, page } } }
                : { href: terms_url }
        },
        point_price(state, { project }) {
            return project?.point_price
        },
        with_points(state, { project }) {
            return project?.with_points
        },
        with_tiers(state, { project }) {
            return project?.with_tiers
        },
        tier_label(state, { project }) {
            return project?.tier_label
        },
        start_date(state, { project }) {
            return project?.start
        },
        end_date(state, { project }) {
            return project?.end
        },
    },

    mutations: {},

    actions: {
        loadAvailability({ getters }, { unitId, from, contact }) {
            return api.post(`/${getters.project.id}/availability`, {
                unit: unitId || null,
                from,
                contact,
            })
        },
        resumeSubscription({ getters }, id) {
            return api.patch(`/${getters.project.id}/subscription/${id}`)
        },
        cancelSubscription({ getters }, id) {
            return api.delete(`/${getters.project.id}/subscription/${id}`)
        },
        loadVenue({ getters }, { id }) {
            return api.post(`/${getters.project.id}/scan`, { id }).then(({ venue }) => venue)
        },
        checkIn({ getters }, { pass_id, data }) {
            return api
                .post(`/${getters.project.id}/${pass_id}/self`.replace('//'), data)
                .then(({ code }) => code)
        },
        getStaticPage({ getters }, { page, lang }) {
            return api.get(`/${getters.project.id}/terms/${page}`, {
                params: { lang },
            })
        },
        fetchSeatMap({ getters }, { venueId, ...data }) {
            return api.post(`/${getters.project.id}/seatmap/${venueId}`, data)
        },
        fetchSeatPlans({ getters }, { venueId, ...data }) {
            return api.post(`/${getters.project.id}/seatplans/${venueId}`, data)
        },
        getProjectVenues({ getters }, { pagination = {} }) {
            return api.post(`/${getters.project.id}/venues`, { ...pagination })
        },
        joinLotterySales({ getters }) {
            return api.post(`/${getters.project.id}/lottery`, {})
        },
    },
}

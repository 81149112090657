<script>
import { mapActions, mapGetters } from 'vuex'

import { KForm, KIcon, KPictureField } from '@/components/_base'
import KFormValidatorsMixin from '@/mixins/KFormValidatorsMixin.vue'

export default {
    mixins: [KFormValidatorsMixin],
    components: { KForm, KPictureField, KIcon },
    data() {
        return {
            types: {
                suggestion: 'suggestion',
                bug: 'bug',
            },
            formData: {
                type: undefined,
                message: '',
                screenshot: undefined,
            },
            loading: false,
            success: false,
        }
    },
    computed: {
        ...mapGetters({ user: 'account/user' }),
    },
    methods: {
        ...mapActions({
            sendFeedback: 'sendFeedback',
        }),
        async onSubmit() {
            try {
                this.loading = true

                await this.sendFeedback(this.formData)

                this.success = true
            } catch (error) {
                this.$root.handleError({ ...error, form: this.$refs['form'] })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<template>
    <TransitionGroup name="fade" enter-from-class="opacity-0" enter-active-class="transition">
        <div v-if="success">
            <div class="w-12 h-12 flex items-center justify-center rounded-full mx-auto bg-green">
                <KIcon icon="CheckIcon" class="text-white" />
            </div>
            <p class="text-center mt-3 mb-2">
                {{ $t('Thank you for your feedback!') }}
            </p>
            <p class="text-center text-sm text-secondary--text">
                {{ $t('We will review your message as soon as possible') }}
            </p>
        </div>
        <div v-else>
            <div class="w-12 h-12 flex items-center justify-center rounded-full mx-auto bg-brand">
                <KIcon
                    :icon="
                        formData.type === types.suggestion
                            ? 'LightBulbIcon'
                            : formData.type === types.bug
                            ? 'BugAntIcon'
                            : 'ChatBubbleOvalLeftEllipsisIcon'
                    "
                    class="text-white"
                />
            </div>
            <p class="text-center mt-3 mb-6">
                {{
                    $t(
                        formData.type === types.suggestion
                            ? 'What is your suggestion?'
                            : formData.type === types.bug
                            ? 'Please explain what happened'
                            : 'Help us improve our service'
                    )
                }}
            </p>
        </div>
        <div v-if="!success && formData.type">
            <KForm @submit="onSubmit" ref="form">
                <KTextField
                    v-if="!$root.authenticated"
                    v-model="formData.email"
                    :placeholder="$t('email')"
                    :rules="
                        combineFieldValidators([
                            fieldValidators.required(),
                            fieldValidators.email(),
                        ])
                    "
                    :fieldAttrs="{ autocomplete: 'email', type: 'email' }"
                    name="email"
                    class="mb-6"
                />
                <KTextField
                    v-model="formData.message"
                    :placeholder="$c($t('write your message here'))"
                    :rules="fieldValidators.required()"
                    :disabled="loading"
                    :rows="3"
                    class="mb-6"
                    name="message"
                    textarea
                />
                <KPictureField
                    v-model="formData.screenshot"
                    :buttonText="$t('upload a screenshot')"
                    :disabled="loading"
                    :rules="combineFieldValidators([fieldValidators.imageSizeMb()])"
                    name="screenshot"
                    class="mb-6"
                />
                <KButton :loading="loading" class="w-full" type="submit">
                    {{ $t('submit') }}
                </KButton>
            </KForm>
        </div>
        <div v-else-if="!success">
            <KButton
                @click="formData.type = types.suggestion"
                iconLeft="LightBulbIcon"
                class="mb-3 w-full"
                mode="secondary"
            >
                {{ $t('I have a suggestion') }}
            </KButton>
            <KButton
                @click="formData.type = types.bug"
                iconLeft="BugAntIcon"
                mode="secondary"
                class="w-full"
            >
                {{ $t('I found a bug') }}
            </KButton>
        </div>
    </TransitionGroup>
</template>
